/*! CSS Used from: Embedded */
.mb-0{margin-bottom:0!important;}
.mt-2{margin-top:.5rem!important;}
.ml-2{margin-left:.5rem!important;}
.mt-4{margin-top:1.5rem!important;}
.p-0{padding:0!important;}
.pl-0{padding-left:0!important;}
.p-3{padding:1rem!important;}
.p-4{padding:1.5rem!important;}
.px-4{padding-right:1.5rem!important;}
.pb-4{padding-bottom:1.5rem!important;}
.px-4{padding-left:1.5rem!important;}

@media (min-width:1200px){
  .mr-xl-2{margin-right:.5rem!important;}
}

.font-weight-bold{
  font-weight:700!important;
}
.text-muted{
  color: #c7c7c7!important;
  font-weight: bold;
}

@media print{
  *,*::before,*::after {
    text-shadow:none!important;
    box-shadow:none!important;
  }
  .icon-container:not(.btn) {
    text-decoration:underline;
  }
  p {
    orphans:3;
    widows:3;
  }
}
.far,.fas{-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;display:inline-block;font-style:normal;font-variant:normal;text-rendering:auto;line-height:1;}
.fa-clock:before{content:"\f017";}
.fa-credit-card:before{content:"\f09d";}
.fa-flag:before{content:"\f024";}
.fa-save:before{content:"\f0c7";}
.fa-thumbs-up:before{content:"\f164";}
.far{font-weight:400;}
.far,.fas{font-family:"Font Awesome 5 Free";}
.fas{font-weight:900;}
.red{background-color:#f44336!important;}
.red.darken-2{background-color:#e91e63!important;}
.white-text{color:#fff!important;}
.z-depth-1{-webkit-box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;}
.z-depth-1-half{-webkit-box-shadow:0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)!important;box-shadow:0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)!important;}
:disabled{pointer-events:none!important;}
h4{font-weight:300;}
ul.stepper{padding:0 1.5rem;padding:1.5rem;margin:1em -1.5rem;overflow-x:hidden;overflow-y:auto;counter-reset:section;}
ul.stepper li{height:-webkit-min-content;height:-moz-min-content;height:min-content;}
ul.stepper li .icon-container{padding:1.5rem;text-align:center;}
ul.stepper li .icon-container .circle{display:inline-block;width:1.75rem;height:1.75rem;margin-right:.5rem;line-height:1.7rem;color:#fff;text-align:center;background:rgba(0,0,0,0.38);border-radius:50%;}
.stepper-vertical{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;}
.stepper-vertical li{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-ms-flex:1;flex:1;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;}
.stepper-vertical li .icon-container{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-item-align:start;align-self:flex-start;}
.stepper-vertical li .icon-container .circle{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1;}
.stepper-vertical li .step-content{display:block;padding:.94rem;margin-top:0;margin-left:3.13rem;}
.stepper-vertical li .step-content p{font-size:.88rem; word-break: break-word;}
.stepper-vertical li:not(:last-child):after{position:absolute;top:3.44rem;left:2.19rem;width:1px;height:calc(100% - 40px);content:"";background-color:rgba(0,0,0,0.1);}
/*! CSS Used from: Embedded */
.timeline-main .stepper.stepper-vertical.timeline li .icon-container{padding:0px 24px;left:50%;}

@media (max-width: 450px){
.timeline-main .stepper.stepper-vertical.timeline li .icon-container{left:6%;}
}

@media (min-width: 451px) and (max-width: 1025px){
.timeline-main .stepper.stepper-vertical.timeline li .icon-container{left:6%;}
}

.timeline-main .stepper.stepper-vertical.timeline li .icon-container .circle{width:50px;height:50px;line-height:50px;font-size:1.4em;text-align:center;position:absolute;top:16px;margin-left:-50px;background-color:#ccc;z-index:2;}
.timeline-main .stepper.stepper-vertical.timeline li .step-content{width:45%;float:left;-webkit-border-radius:2px;border-radius:2px;position:relative;}

@media (max-width: 450px){
.timeline-main .stepper.stepper-vertical.timeline li .step-content{width:80%;left:3rem;margin-right:3rem;margin-bottom:2rem;float:right;}
}

@media (min-width: 451px) and (max-width: 1025px){
.timeline-main .stepper.stepper-vertical.timeline li .step-content{width:85%;left:3rem;margin-right:3rem;margin-bottom:2rem;float:right;}
}
.timeline-main .stepper.stepper-vertical.timeline li .step-content:before{position:absolute;top:26px;right:-15px;display:inline-block;border-top:15px solid transparent;border-left:15px solid #e0e0e0;border-right:0 solid #e0e0e0;border-bottom:15px solid transparent;content:" ";}

@media (max-width: 450px){
.timeline-main .stepper.stepper-vertical.timeline li .step-content:before{border-left-width:0;border-right-width:15px;left:-15px;right:auto;}
}

@media (min-width: 451px) and (max-width: 1025px){
.timeline-main .stepper.stepper-vertical.timeline li .step-content:before{border-left-width:0;border-right-width:15px;left:-15px;right:auto;}
}
.timeline-main .stepper.stepper-vertical.timeline li .step-content:after{position:absolute;top:27px;right:-14px;display:inline-block;border-top:14px solid transparent;border-left:14px solid #fff;border-right:0 solid #fff;border-bottom:14px solid transparent;content:" ";}

@media (max-width: 450px){
.timeline-main .stepper.stepper-vertical.timeline li .step-content:after{border-left-width:0;border-right-width:14px;left:-14px;right:auto;}
}

@media (min-width: 451px) and (max-width: 1025px){
.timeline-main .stepper.stepper-vertical.timeline li .step-content:after{border-left-width:0;border-right-width:14px;left:-14px;right:auto;}
}
.timeline-main .stepper.stepper-vertical.timeline li.timeline-inverted{-webkit-box-align:end;-webkit-align-items:flex-end;-ms-flex-align:end;align-items:flex-end;}
.timeline-main .stepper.stepper-vertical.timeline li.timeline-inverted .step-content{float:right;}
.timeline-main .stepper.stepper-vertical.timeline li.timeline-inverted .step-content:before{border-left-width:0;border-right-width:15px;left:-15px;right:auto;}
.timeline-main .stepper.stepper-vertical.timeline li.timeline-inverted .step-content:after{border-left-width:0;border-right-width:14px;left:-14px;right:auto;}
.timeline-main .stepper.stepper-vertical.timeline li:not(:last-child):after{content:" ";position:absolute;width:3px;background-color:#e0e0e0;left:50%;top:65px;margin-left:-1.5px;}

@media (max-width: 450px){
.timeline-main .stepper.stepper-vertical.timeline li:not(:last-child):after{left:6%;}
}

@media (min-width: 451px) and (max-width: 1025px){
.timeline-main .stepper.stepper-vertical.timeline li:not(:last-child):after{left:6%;}
}

@media (max-width: 1025px){
.timeline-main .stepper.stepper-vertical.timeline li{-webkit-box-align:end;-webkit-align-items:flex-end;-ms-flex-align:end;align-items:flex-end;}
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:before{top:1rem;border-left:15px solid #e91e63;border-right:0 solid #e91e63;}

@media (max-width: 450px){
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:before{border-left-width:0;border-right-width:15px;left:-15px;right:auto;}
}

@media (min-width: 451px) and (max-width: 1025px){
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:before{border-left-width:0;border-right-width:15px;left:-15px;right:auto;}
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:after{top:1rem;border-left:14px solid #e91e63;border-right:0 solid #e91e63;}

@media (max-width: 450px){
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:after{border-left-width:0;border-right-width:14px;left:-14px;right:auto;}
}

@media (min-width: 451px) and (max-width: 1025px){
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content:after{border-left-width:0;border-right-width:14px;left:-14px;right:auto;}
}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .step-content .timeline-header{-webkit-border-top-left-radius:2px;border-top-left-radius:2px;-webkit-border-top-right-radius:2px;border-top-right-radius:2px;}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li.timeline-inverted .step-content:before{border-left-width:0;border-right-width:15px;left:-15px;}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li.timeline-inverted .step-content:after{border-left-width:0;border-right-width:14px;left:-14px;}
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li:not(:last-child):after{background-color:#e91e63;}

@media (max-width: 450px){
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .icon-container{left:8%;}
}

@media (max-width: 450px){
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li .icon-container .circle{width:30px;height:30px;line-height:30px;font-size:.9em;top:26px;margin-left:-35px;}
}

@media (max-width: 450px){
.timeline-main .stepper.stepper-vertical.timeline.colorful-timeline li:not(:last-child):after{left:9%;height:100%;top:55px;}
}
@media (max-width: 599px) {
  ul.stepper {
    margin: 1em 0 !important;
    padding:0;
  }
}
/*! CSS Used fontfaces */
@font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:400;font-display:auto;}
@font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:900;font-display:auto;}
